export function numberWithCommas(x: number) {
  if (x === 0) return "0";

  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return null;
}

export function formatPhone(x: string) {
  if (x) {
    return x.replace(/^(\d{3})(\d{0,4})(\d{0,4})$/, (match, p1, p2, p3) => {
      let formattedNumber = p1;
      if (p2) {
        formattedNumber += `-${p2}`;
      }
      if (p3) {
        formattedNumber += `-${p3}`;
      }
      return formattedNumber;
    });
  }

  return;
}
