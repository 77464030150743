import React from "react";
import { Route, Routes } from "react-router-dom";
import { IRoute } from "src/interfaces";
import Page404 from "src/pages/system/404";
import UnauthorizedPage from "src/pages/system/Unauthorized";
import RequireAuth from "./RequireAuth";
import { listNoAuthRouters, listPrivateRouters } from "./routers";

function RenderRouters() {
  const logicRenderRoute = (
    { children = [], element, permissions, layout, ...rest }: IRoute,
    index: number,
    isAuth = false
  ) => {
    const renderElement = isAuth ? (
      <RequireAuth permissions={permissions}>{element}</RequireAuth>
    ) : (
      element ?? <React.Fragment />
    );

    if (children && children.length > 0) {
      return (
        <Route {...rest} element={renderElement} key={index}>
          {children.map((route, indexx: number) => {
            return logicRenderRoute(route, indexx, isAuth);
          })}
        </Route>
      );
    }

    return <Route {...rest} element={renderElement} key={index} />;
  };

  // if (loading) {
  //   return <LoadingPage />;
  // }

  return (
    <Routes>
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      {/* <Route path="/" element={<Dashboardpage />} /> */}

      {listNoAuthRouters.map((route: any, index) => {
        return logicRenderRoute(route, index);
      })}

      {listPrivateRouters.map((route: any, index) => {
        return logicRenderRoute(route, index, true);
      })}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}

export default RenderRouters;
