import { Select, SelectProps } from "antd";
import { ArrowDownIcon } from "src/assets/icons";
import "./index.scss";
const { Option } = Select;
const SelectComponent = (props: SelectProps<any, any>) => {
  const { options, ...rest } = props;

  return (
    <Select
      className="SelectStyle"
      suffixIcon={<ArrowDownIcon fill="black" />}
      dropdownMatchSelectWidth={false}
      {...rest}
    >
      {options?.map((o, index) => {
        return (
          <Option key={index.toString()} value={o.value} title={""}>
            {o.children}
            {o.label}
          </Option>
        );
      })}
    </Select>
  );
};

export default SelectComponent;
