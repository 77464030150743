import { useState } from "react";
import useAuth from "src/hooks/useAuth";
import { InputComponent, ModalComponent } from "../share";
import Button from "../share/Button";
import { ACCESS_TOKEN } from "src/helper";

function ModalLoginComponent() {
  const { showPopupLogin, setShowPopupLogin, setAuth }: any = useAuth();
  const [user, setUser] = useState<{ userName: string; password: string }>({
    userName: "",
    password: "",
  });
  if (!showPopupLogin) {
    return null;
  }

  const closeLoginModal = () => {
    setShowPopupLogin(false);
  };

  const onSubmit = async () => {
    // await login();
    const acc = "token fake";
    localStorage.setItem(ACCESS_TOKEN, acc);
    setAuth((pre: any) => {
      return {
        ...pre,
        access_token: "fake",
        loading: false,
        user: {
          id: 1,
          name: "SON.GNOUH",
          hasBankAcc: true,
          userName: user?.userName,
        },
      };
    });

    setShowPopupLogin(false);
  };

  return (
    <ModalComponent
      title="ĐĂNG NHẬP"
      centered
      open={showPopupLogin}
      onOk={closeLoginModal}
      onCancel={closeLoginModal}
      width={380}
      // height={500}
      footer={[]}
      maskClosable={false}
    >
      <div>
        <InputComponent
          containerProps={{ style: { marginBottom: 16 } }}
          inputprops={{
            placeholder: "Tài khoản/SĐT",
            value: user.userName,
          }}
          onChangeText={(text) => setUser({ ...user, userName: text })}
          error={!user.userName.length ? "Bắt buộc nhập" : ""}
        />

        <InputComponent
          containerProps={{ style: { marginBottom: 12 } }}
          inputprops={{
            type: "password",
            placeholder: "Mật khẩu",
            value: user.password,
          }}
          onChangeText={(text) => setUser({ ...user, password: text })}
          error={!user.password.length ? "Bắt buộc nhập" : ""}
        />

        <Button buttonLabel={"ĐĂNG NHẬP"} onClick={onSubmit} />
      </div>
    </ModalComponent>
  );
}

export default ModalLoginComponent;
