import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ModalLoginComponent } from "src/components";
import { ACCESS_TOKEN } from "src/helper";
import { IAuth } from "src/interfaces";

export interface AuthContextType {
  auth?: IAuth;
  logout: () => void;
  setAuth: Dispatch<SetStateAction<IAuth | undefined>>;
  showPopupLogin: boolean;
  setShowPopupLogin: Dispatch<SetStateAction<boolean>>;
}

const AuthContext = createContext<AuthContextType>(null!);
interface IProps {
  children: React.ReactNode;
}

const AuthContextProvider = ({ children }: IProps) => {
  const [auth, setAuth] = useState<IAuth | undefined>({
    user: undefined,
    access_token: "fake",
    loading: false,
  });

  const [showPopupLogin, setShowPopupLogin] = useState<boolean>(false);

  const logout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    setAuth({
      user: undefined,
      access_token: "",
      loading: true,
    });
    window.location.href = "/";
  };

  const fetchProfile = useCallback(async (token: string) => {
    // const response = await AuthedServices.getProfile({ param_datas: {} });
    // if (response && response.data) {
    //   setAuth((pre: any) => {
    //     return {
    //       ...pre,
    //       access_token: token,
    //       user: { ...response.data },
    //     };
    //   });
    // }
    // TODO UPDATE FETCH API
    setAuth((pre: any) => {
      return {
        ...pre,
        access_token: "fake",
        loading: false,
        user: {
          id: 1,
          name: "SON.GNOUH",
          hasBankAcc: true,
          userName: "ANHLASO1",
        },
      };
    });
  }, []);

  useEffect(() => {
    const token = "localStorage.getItem(ACCESS_TOKEN)";

    if (token) {
      // fetchProfile(token)
      //   .catch(console.error)
      //   .finally(() => {
      //     setAuth((pre: any) => {
      //       return {
      //         ...pre,
      //         loading: false,
      //       };
      //     });
      //   });
      setAuth((pre: any) => {
        return {
          ...pre,
          access_token: "fake",
          loading: false,
          user: {
            id: 1,
            name: "SON.GNOUH",
            hasBankAcc: true,
            userName: "ANHLASO1",
          },
        };
      });
    }
  }, [fetchProfile]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        showPopupLogin,
        setShowPopupLogin,
        logout,
      }}
    >
      {children}
      <ModalLoginComponent />
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
