import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const setToken = (token: string) => {
  cookies.set("access_token", token);
};

export const getToken = (): string => {
  return cookies.get("access_token");
};

export const removeToken = () => {
  cookies.remove("access_token");
};

export const setUsername = (token: string) => {
  cookies.set("username", token);
};

export const getUsername = (): string => {
  return cookies.get("username");
};

export const removeUsername = () => {
  cookies.remove("username");
};
