import {
  Input,
  InputNumber,
  InputNumberProps,
  InputProps,
  InputRef,
} from "antd";
import { ChangeEvent, HTMLAttributes, ReactElement, useState } from "react";
import "./index.scss";

export interface InputComponentProps {
  defaultValue?: string | number;
  inputprops?: InputProps & React.RefAttributes<InputRef>;
  onChangeText?: (text: string) => void;
  rightIcon?: ReactElement;
  leftIcon?: ReactElement;
  onClick?: () => void;
  containerProps?: HTMLAttributes<HTMLDivElement>;
  inputNumberProps?: InputNumberProps;
  error?: string;
}

const InputComponent = (props: InputComponentProps) => {
  const {
    defaultValue,
    inputprops,
    onChangeText,
    rightIcon,
    containerProps,
    onClick,
    inputNumberProps,
    leftIcon,
    error,
  } = props;

  const [text, setText] = useState(defaultValue ?? "");

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value.toString());
    onChangeText && onChangeText(e.target.value.toString());
  };
  const onChangNumber = (e: any) => {
    setText(Number(e.target.value).toString());
    onChangeText && onChangeText(Number(e.target.value).toString());
  };

  const onKeyDown = (e: any) => {
    if (
      e.key === "," ||
      (isNaN(Number(e.key)) && e.key !== "." && e.key !== "Backspace")
    ) {
      e.preventDefault();
      setText(e.target.value);
      onChangeText && onChangeText(e.target.value);
    }
  };

  const renderInput = () => {
    switch (inputprops?.type) {
      case "number":
        return (
          // <InputNumber
          //   onKeyDown={onKeyDown}
          //   className={`input-search ${leftIcon ? "ps-30px" : ""}`}
          //   onChange={onChangNumber}
          //   {...inputNumberProps}
          // />
          <Input
            value={
              text
                ? Number(text) % 1 != 0
                  ? Number(text).toFixed(2)
                  : Number(text)
                : undefined
            }
            onChange={onChangNumber}
            onKeyDown={onKeyDown}
            className={`input-search ${leftIcon ? "ps-30px" : ""} ${
              error ? "error" : ""
            }`}
            placeholder="placeholder..."
            {...inputprops}
          />
        );
      case "password":
        return (
          <Input.Password
            value={text}
            onChange={onChange}
            className={`input-search ${leftIcon ? "ps-30px" : ""} ${
              error ? "error" : ""
            }`}
            placeholder="placeholder..."
            {...inputprops}
          />
        );

      default:
        return (
          <Input
            // maxLength={50}
            value={text}
            onChange={onChange}
            className={`input-search ${leftIcon ? "ps-30px" : ""} ${
              error ? "error" : ""
            }`}
            placeholder="placeholder..."
            {...inputprops}
          />
        );
    }
  };
  return (
    <div className={"InputStyle"} {...containerProps}>
      <div
        className={`wrap-input-search-popup ${error ? "error" : ""}`}
        onClick={onClick}
      >
        <div className="wrap-icon-left">{leftIcon ? leftIcon : <></>}</div>
        {renderInput()}
        <div className="wrap-icon-search">{rightIcon ? rightIcon : <></>}</div>
      </div>
      <span className="error-text">{error}</span>
    </div>
  );
};

export default InputComponent;
