import { ButtonHTMLAttributes, HTMLAttributes, ReactElement } from "react";
import "./index.scss";

export interface ButtonComponentProps {
  onClick?: () => void;
  containerProps?: HTMLAttributes<HTMLDivElement>;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  buttonLabel?: string | null;
  varient?: "submit" | "outline";
  style?: React.CSSProperties;
  left?: ReactElement;
  right?: ReactElement;
}

const ButtonComponent = (props: ButtonComponentProps) => {
  const {
    onClick,
    buttonLabel = "Submit",
    varient = "submit",
    buttonProps,
    containerProps,
    style,
    left,
    right,
  } = props;

  return (
    <div className="button-container" {...containerProps} style={{ ...style }}>
      <button className={`btn ${varient}`} onClick={onClick} {...buttonProps}>
        {left ? left : <div>{left}</div>}
        {buttonLabel}
        {right ? right : <div>{right}</div>}
      </button>
    </div>
  );
};

export default ButtonComponent;
