// export enum SOCCER_CHILD_MENU {
//   FT1H = "FT1H",
//   CORNER = "CORNER",
//   BET_15P = "BET_15P",
//   SCORE = "SCORE",
//   SOCCER_CHILD_MENU = "SOCCER_CHILD_MENU",
//   NUMBER_OF_GOALS = "NUMBER_OF_GOALS",
//   HALF_OR_ALL = "HALF_OR_ALL",
// }

export enum Info {
  BET,
  NEW_APP,
}

export enum BetType {
  SINGLE,
  MULTI,
}

export enum BetFor {
  Home = "home",
  Guest = "guest",
  Draw = "draw",
}

export enum GameId {
  Soccer = 1,
  Basketball = 2,
  Tennis = 3,
  VolleyBall = 4,
  Badminton = 5,
  PingPong = 6,
}

export enum SidebarGameTypes {
  Soon,
  Live,
}

export enum GameTypeId {
  Soccer_FT1H = 1,
  Soccer_CorrectScore = 2,
  Soccer_TotalGoal = 3,
  Soccer_Corner = 4,
  Soccer_15Mins = 5,
  Soccer_HalfFull = 6,

  Basketball_FT1H = 11,
  Basketball_1H = 12,
  Basketball_3Point = 13,
  Basketball_TotalPoint = 14,

  Tennis_Game = 21,
  Tennis_Set = 22,

  VolleyBall_FT = 31,
  VolleyBall_1H = 32,

  Badminton_FT = 41,
  Badminton_1H = 42,

  PingPong_FT = 51,
  PingPong_1H = 52,
}
