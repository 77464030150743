import { NoBetIcon } from "src/assets/icons";

interface ExchangeCashProps {
  onChangeLoading?: (val: boolean) => void;
  content?: any;
}

export const ExchangeCash = ({ content }: ExchangeCashProps) => {
  return (
    <div className="ExchangeCashStyle">
      <div className="th">
        <div>Thông tin đơn cược</div>
        <div>Nội dung đặt cược</div>
        <div>Tiền cược</div>
        <div>Số dư sau cược</div>
        <div>Hoàn trả tối đa</div>
        <div>Đổi tiền mặt</div>
      </div>
      {content ? (
        <div className="content"></div>
      ) : (
        <div className="no-content">
          <NoBetIcon />
          <div>Tạm thời không có đợn cược</div>
        </div>
      )}
    </div>
  );
};

export default ExchangeCash;
