import { Typography } from "antd";
import Checkbox, {
  CheckboxChangeEvent,
  CheckboxProps,
} from "antd/es/checkbox/Checkbox";
import { HTMLAttributes, useEffect, useState } from "react";
import { Option } from "src/helper";
import "./index.scss";

type CheckboxComponentProps = {
  onClick?: () => void;
  containerProps?: HTMLAttributes<HTMLDivElement>;
  options: Option[];
  defaultValue?: Option[];
  onChangeValue?: (listCheck: Option[]) => void;
  widthOption?: number;
} & CheckboxProps;

const { Paragraph } = Typography;
const CheckboxComponent = (props: CheckboxComponentProps) => {
  const {
    onChangeValue,
    defaultValue,
    containerProps,
    onClick,
    options,
    widthOption,
    ...rest
  } = props;

  const [listChecked, setListChecked] = useState<Option[]>(defaultValue ?? []);

  useEffect(() => {
    if (defaultValue && defaultValue !== listChecked)
      setListChecked(defaultValue);

    if (defaultValue?.length === 0 || !defaultValue) {
      setListChecked([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const isCheck = (value: Option) => {
    return Boolean(
      listChecked?.findIndex(
        (o) => o.value.toString() === value.value.toString()
      ) >= 0
    );
  };

  const onChange = (e: CheckboxChangeEvent) => {
    let newList: Option[] = [];

    if (e.target.checked) {
      const option = options.find((o) => o.value === e.target.value);
      if (option) newList = [...listChecked, option];
    } else {
      newList = listChecked.filter((o) => o.value !== e.target.value);
    }
    setListChecked(newList);
    onChangeValue && onChangeValue(newList);
  };

  return (
    <div className={"CheckboxStyle"} onClick={onClick} {...containerProps}>
      {[...options]?.map((o, index) => {
        return (
          <Checkbox
            key={index.toString()}
            value={o.value}
            checked={isCheck(o)}
            onChange={onChange}
            {...rest}
          >
            <Paragraph
              className="label-check"
              ellipsis={true}
              style={{
                width: widthOption,
                fontSize: o.note ? 13 : undefined,
                lineHeight: o.note ? "15px" : undefined,
              }}
            >
              {o.label}
              {o.note ? (
                <>
                  <span className="note">{o.note}</span>
                </>
              ) : (
                ""
              )}
            </Paragraph>
          </Checkbox>
        );
      })}
    </div>
  );
};

export default CheckboxComponent;
