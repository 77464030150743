import { notification } from "antd";
import { useCallback, useEffect } from "react";
// import { CheckFullIcon, WarningIcon } from "src/assets/icons";

import "./index.scss";
import useApp from "src/hooks/useApp";
import { AppContextType } from "src/helper";

const SnackbarComponent = () => {
  const { snackbar, onChangeSnackbar } = useApp() as AppContextType;
  const [api, contextHolder] = notification.useNotification();

  const openSnackbar = useCallback(() => {
    api.open({
      type: snackbar?.type ?? "success",
      message: snackbar?.message ?? "Success",
      description: (
        <div
          dangerouslySetInnerHTML={{ __html: snackbar?.description ?? "" }}
        ></div>
      ),
      duration: 3,
      className: "snackbar-container",
      // icon: snackbar?.type === "success" ? <CheckFullIcon /> : <WarningIcon />,
    });
  }, [api, snackbar]);

  useEffect(() => {
    if (snackbar) {
      openSnackbar();
      onChangeSnackbar(undefined);
    }
  }, [openSnackbar, snackbar]);

  return <>{contextHolder}</>;
};

export default SnackbarComponent;
