import {
  BadmintonIcon,
  BasketballIcon,
  BilliardIcon,
  FootballIcon,
  HandballIcon,
  HockeyIcon,
  LiveTVIcon,
  PCgameIcon,
  PingpongIcon,
  SoccerIcon,
  TennisIcon,
  VolleyballIcon,
  WaterpoloIcon,
  OlympicIcon,
  MenuRuleIcon,
  MenuCashIcon,
  BaseBallIcon,
} from "src/assets/icons";
import {
  MenuItem,
  MenuRuleItem,
  Option,
  SchedulesFromMenuItem,
  TournamentItem,
} from "./types";
import { GameTypeId } from "./enums";

export const ImageItemListRatio: number = 3 / 4;
export const VITE_SOCKET_URL = process.env.REACT_APP_VITE_SOCKET_URL as string;
export const VITE_API_URL = process.env.REACT_APP_VITE_API_URL as string;

export const ACCESS_TOKEN = "access_token";
export const SCREEN_WIDTH = window.screen.width;
export const SCREEN_HEIGHT = window.screen.height;
export const PAGE_SIZE = 10;
export const DEFAULT_PAGING = {
  current: 1,
  pageSize: 10,
  total: 0,
};

export const BankOptions: Option[] = [
  { label: " Xin chọn ngân hàng", value: "0" },

  { label: "ABBank", value: "ABBank" },

  { label: "ACB", value: "ACB" },

  { label: "Agribank", value: "Agribank" },

  { label: "Bac A Bank", value: "Bac A Bank" },

  { label: "BaoVietBank", value: "BaoVietBank" },

  { label: "BIDV", value: "BIDV" },

  { label: "Dong A bank", value: "Dong A bank" },

  { label: "Eximbank", value: "Eximbank" },

  { label: "GPBank", value: "GPBank" },

  { label: "Hdbank", value: "Hdbank" },

  { label: "HSBC", value: "HSBC" },

  { label: "Indovina", value: "Indovina" },

  { label: "KienLongBank", value: "KienLongBank" },

  { label: "LienVietPostBank", value: "LienVietPostBank" },

  { label: "Maritime", value: "Maritime" },

  { label: "Mbbank", value: "Mbbank" },

  { label: "Nam A bank", value: "Nam A bank" },

  { label: "NCB", value: "NCB" },

  { label: "OCB Phuong Dong", value: "OCB Phuong Dong" },

  { label: "Oceanbank", value: "Oceanbank" },

  { label: "Pgbank", value: "Pgbank" },

  { label: "PVCombank", value: "PVCombank" },

  { label: "Sacombank", value: "Sacombank" },

  { label: "SaiGonBank", value: "SaiGonBank" },

  { label: "Seabank", value: "Seabank" },

  { label: "SHB", value: "SHB" },

  { label: "ShinHanBank", value: "ShinHanBank" },

  { label: "Techcombank", value: "Techcombank" },

  { label: "Tien Phong Bank", value: "Tien Phong Bank" },

  { label: "TMCP SAIGON", value: "TMCP SAIGON" },

  { label: "VIBbank", value: "VIBbank" },

  { label: "VietA Bank", value: "VietA Bank" },

  { label: "Vietbank", value: "Vietbank" },

  { label: "Vietcombank", value: "Vietcombank" },

  { label: "Vietinbank", value: "Vietinbank" },

  { label: "VPBank", value: "VPBank" },
];

export const DEFAULT_MENU: MenuItem[] = [
  {
    key: "0",
    label: "Live",
    icon: LiveTVIcon,
    count: 37,
    children: [],
    isShowChildren: false,
    keyCount: "like",
  },
  {
    key: "1",
    label: "Bóng đá",
    icon: SoccerIcon,
    count: 407,
    keyCount: "soccer",
    children: [
      {
        key: GameTypeId.Soccer_FT1H,
        label: "FT / 1H",
        keyCount: "soccerFT1HCount",
        count: 245,
      },
      {
        key: GameTypeId.Soccer_Corner,
        label: "Phạt góc",
        keyCount: "soccerCornerCount",
        count: 15,
      },
      {
        key: GameTypeId.Soccer_15Mins,
        label: "Kèo 15 phút",
        keyCount: "soccer15MinsCount",
        count: 17,
      },
      {
        key: GameTypeId.Soccer_CorrectScore,
        label: "Tỉ số",
        keyCount: "soccerCorrectScoreCount",
        count: 73,
      },
      {
        key: GameTypeId.Soccer_TotalGoal,
        label: "Số bàn thắng",
        keyCount: "soccerTotalGoalCount",
        count: 73,
      },
      {
        key: GameTypeId.Soccer_HalfFull,
        label: "Nửa / cả trận",
        keyCount: "soccerHalfFullCount",
        count: 73,
      },
    ],
    isShowChildren: true,
  },
  {
    key: "2",
    label: "Bóng rổ",
    icon: BasketballIcon,
    count: 407,
    keyCount: "basketball",
    children: [
      {
        key: GameTypeId.Basketball_FT1H,
        label: "FT / 1H",
        keyCount: "basketballFT1HCount",
        count: 245,
      },
      {
        key: GameTypeId.Basketball_1H,
        label: "Một hiệp",
        keyCount: "basketball1HCount",
        count: 15,
      },
      {
        key: GameTypeId.Basketball_3Point,
        label: "Số lần ghi 3 điểm",
        keyCount: "basketball3PointCount",
        count: 17,
      },
      {
        key: GameTypeId.Basketball_TotalPoint,
        label: "Tổng điểm một đội",
        keyCount: "basketballTotalPointCount",
        count: 73,
      },
    ],
    isShowChildren: false,
  },
  {
    key: "3",
    label: "Quần vợt",
    icon: TennisIcon,
    count: 407,
    keyCount: "soccer",
    children: [
      {
        key: GameTypeId.Tennis_Game,
        label: " Game / Set",
        keyCount: "tennisGameCount",
        count: 17,
      },
      {
        key: GameTypeId.Tennis_Set,
        label: "Một Set",
        keyCount: "tennisSetCount",
        count: 73,
      },
    ],
    isShowChildren: false,
  },
  {
    key: "8",
    label: "Bóng Chuyền",
    icon: VolleyballIcon,
    count: 0,
    children: [
      {
        key: GameTypeId.VolleyBall_FT,
        label: " Hiệp / Tổng điểm",
        keyCount: "volleyballFTCount",
        count: 17,
      },
      {
        key: GameTypeId.VolleyBall_1H,
        label: "Một Hiệp",
        keyCount: "volleyball1HCount",
        count: 73,
      },
    ],
    isShowChildren: false,
    keyCount: "Bóng Chuyền",
  },
  {
    key: "9",
    label: "Cầu Lông",
    icon: BadmintonIcon,
    count: 0,
    children: [
      {
        key: GameTypeId.Badminton_FT,
        label: " Hiệp / Tổng điểm",
        keyCount: "badmintonFTCount",
        count: 17,
      },
      {
        key: GameTypeId.Badminton_1H,
        label: "Một Hiệp",
        keyCount: "badminton1HCount",
        count: 73,
      },
    ],
    isShowChildren: false,
    keyCount: "Cầu Lông",
  },
  {
    key: "4",
    label: "Bóng bàn",
    icon: PingpongIcon,
    count: 407,
    keyCount: "soccer",
    children: [
      {
        key: GameTypeId.PingPong_FT,
        label: " Hiệp / Tổng điểm",
        keyCount: "pingpongFTCount",
        count: 17,
      },
      {
        key: GameTypeId.PingPong_1H,
        label: "Một Hiệp",
        keyCount: "pingpong1HCount",
        count: 73,
      },
    ],
    isShowChildren: false,
  },
  {
    key: "6",
    label: "Ice Hockey",
    icon: HockeyIcon,
    count: 0,
    children: [],
    isShowChildren: false,
    keyCount: "Ice Hockey",
  },
  {
    key: "7",
    label: "Bóng Bầu Dục",
    icon: FootballIcon,
    count: 0,
    children: [],
    isShowChildren: false,
    keyCount: "Bóng BầuDục",
  },

  {
    key: "10",
    label: "Bóng Ném",
    icon: HandballIcon,
    count: 0,
    children: [],
    isShowChildren: false,
    keyCount: "Bóng Ném",
  },
  {
    key: "11",
    label: "Bóng Nước",
    icon: WaterpoloIcon,
    count: 0,
    children: [],
    isShowChildren: false,
    keyCount: "Bóng Nước",
  },
  {
    key: "12",
    label: "Bida ",
    icon: BilliardIcon,
    count: 0,
    children: [],
    isShowChildren: false,
    keyCount: "Bida ",
  },
  {
    key: "5",
    label: "E-sports",
    icon: PCgameIcon,
    count: 407,
    keyCount: "soccer",
    children: [],
    isShowChildren: false,
  },
];

export const ScheduleListFromMenu: SchedulesFromMenuItem[] = [
  {
    menuKey: GameTypeId.Soccer_FT1H,
    schedules: [
      { key: "Bóng đá", label: "Bóng đá" },
      { key: "FT Kèo chấp", label: "FT Kèo chấp" },
      { key: "FT Tài xỉu", label: "FT Tài xỉu" },
      { key: "FT 1X2", label: "FT 1X2" },
      { key: "1H Kèo chấp", label: "1H Kèo chấp" },
      { key: "1H Tài xỉu", label: "1H Tài xỉu" },
      { key: "1H 1X2", label: "1H 1X2" },
      { key: "Thêm", label: "Thêm" },
    ],
  },
  {
    menuKey: GameTypeId.Soccer_Corner,
    schedules: [
      { key: "Phạt góc", label: "Phạt góc", style: { borderRightWidth: 0 } },
      { key: "FT Kèo chấp", label: "FT Kèo chấp" },
      { key: "FT Tài xỉu", label: "FT Tài xỉu" },
      { key: "FT 1X2", label: "FT 1X2" },
      { key: "FT Lẻ chẵn", label: "FT Lẻ chẵn" },
    ],
  },
  {
    menuKey: GameTypeId.Soccer_15Mins,
    schedules: [
      { key: "Kèo 15 phút", label: "Kèo 15 phút" },
      { key: "Kèo chấp", label: "Kèo chấp" },
      { key: "Tài xỉu", label: "Tài xỉu" },
      { key: "1X2", label: "1X2" },
    ],
  },
  {
    menuKey: GameTypeId.Soccer_CorrectScore,
    schedules: [
      {
        key: "Tỉ số",
        label: "Tỉ số",
        style: { borderRightWidth: 0 },
      },
      {
        key: "1",
        label: "",
      },
    ],
  },
  {
    menuKey: GameTypeId.Soccer_TotalGoal,
    schedules: [
      { key: "Số bàn thắng", label: "Số bàn thắng" },
      { key: "FT 0 - 1", label: "FT 0 - 1" },
      { key: "FT 2 - 3", label: "FT 2 - 3" },
      { key: "FT 4 - 6", label: "FT 4 - 6" },
      { key: "FT 7+", label: "FT 7+" },
      { key: "1H 0", label: "1H 0" },
      { key: "1H 1", label: "1H 1" },
      { key: "1H 2", label: "1H 2" },
      { key: "1H 3+", label: "1H 3+" },
    ],
  },
  {
    menuKey: GameTypeId.Soccer_HalfFull,
    schedules: [
      { key: "Nửa / Cả trận", label: "Nửa / Cả trận" },
      {
        key: "1",
        label: "",
      },
    ],
  },
];

export const TournamentsSampleData: TournamentItem[] = [
  {
    id: 1,
    name: "Ngoại hạng anh",
    matchs: [
      {
        id: "m1",
        home: "Manchester City",
        away: "Manchester United",
        type: "2H",
        home_score: "2",
        away_score: "0",
        betGroupList: [
          {
            type: "FT Tài xỉu",
            betList: [
              {
                key: "03",
                home: { left: "0.6", right: "0.6" },
                away: { left: " ", right: "1.6" },
                draw: "1.333",
              },
              {
                key: "04",
                home: { left: "0.6", right: "0.6" },
                away: { left: " ", right: "1.05" },
              },
            ],
          },
          {
            type: "FT Tài xỉu",
            betList: [
              {
                key: "12",
                home: { left: "2.5", right: "1.02" },
                away: { left: "Xỉu", right: "0.92" },
              },
            ],
          },
        ],
      },
      {
        id: "m2",
        home: "Arsenal",
        away: "Bayern",
        type: "HT",
        home_score: "1",
        away_score: "0",
        betGroupList: [
          {
            type: "FT Kèo chấp",
            betList: [
              {
                key: "01",
                home: { left: "0.6", right: "0.6" },
                away: { left: " ", right: "1.6" },
                draw: "2",
              },
              {
                key: "02",
                home: { left: "0.6", right: "0.6" },
                away: { left: " ", right: "1.05" },
              },
            ],
          },
          {
            type: "FT Tài xỉu",
            betList: [
              {
                key: "11",
                home: { left: "2.5", right: "1.02" },
                away: { left: "Xỉu", right: "0.92" },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Champions League",
    matchs: [
      {
        id: "m3",
        home: "Manchester City",
        away: "Real Madrid",
        type: "2H",
        home_score: "2",
        away_score: "0",
        betGroupList: [
          {
            type: "FT Kèo chấp",
            betList: [
              {
                key: "05",
                home: { left: "0.6", right: "0.6" },
                away: { left: " ", right: "1.6" },
              },
              {
                key: "06",
                home: { left: "0.6", right: "0.6" },
                away: { left: " ", right: "1.05" },
              },
            ],
          },
          {
            type: "FT Tài xỉu",
            betList: [
              {
                key: "13",
                home: { left: "2.5", right: "1.02" },
                away: { left: "Xỉu", right: "0.92" },
              },
            ],
          },
        ],
      },
      {
        id: "m4",
        home: "Arsenal",
        away: "Bayern",
        type: "HT",
        home_score: "2",
        away_score: "0",
        betGroupList: [
          {
            type: "FT Kèo chấp",
            betList: [
              {
                key: "07",
                home: { left: "0.6", right: "0.6" },
                away: { left: " ", right: "1.6" },
                draw: "2",
              },
              {
                key: "08",
                home: { left: "0.6", right: "0.6" },
                away: { left: " ", right: "1.05" },
              },
            ],
          },
          {
            type: "FT Tài xỉu",
            betList: [
              {
                key: "14",
                home: { left: "2.5", right: "1.02" },
                away: { left: "Xỉu", right: "0.92" },
              },
            ],
          },
        ],
      },
    ],
  },
];

export const DEFAULT_RULES_MENU: MenuRuleItem[] = [
  { key: "Quy tắc", label: "Quy tắc", icon: MenuRuleIcon, children: [] },
  {
    key: "Đổi tiền mặt sớm",
    label: "Đổi tiền mặt sớm",
    icon: MenuCashIcon,
    children: [],
  },
  {
    key: "Bóng Đá",
    label: "Bóng Đá",
    icon: SoccerIcon,
    children: [
      { key: "Quy tắc chung", label: "Quy tắc chung" },
      { key: "1X2", label: "1X2" },
      { key: "Kèo chấp", label: "Kèo chấp" },
      { key: "Tài xỉu", label: "Tài xỉu" },
      { key: "Tỉ số", label: "Tỉ số" },
      { key: "Số bàn thắng", label: "Số bàn thắng" },
      { key: "Nửa / Cả trận", label: "Nửa / Cả trận" },
      { key: "Kèo đặc biệt", label: "Kèo đặc biệt" },
      { key: "Hiệp phụ / Luân lưu", label: "Hiệp phụ / Luân lưu" },
      { key: "Bãi biển / Trong nhà", label: "Bãi biển / Trong nhà" },
    ],
  },
  { key: "b2", label: "Bóng Rổ", icon: BasketballIcon, children: [] },
  {
    key: "b3",
    label: "Bóng Chày",
    icon: BaseBallIcon,
    children: [],
  },
  { key: "b4", label: "Quần Vợt", icon: TennisIcon, children: [] },
  {
    key: "b5",
    label: "Ice Hockey",
    icon: HockeyIcon,
    children: [],
  },
  {
    key: "b6",
    label: "Bóng Bầu Dục",
    icon: FootballIcon,
    children: [],
  },
  {
    key: "b7",
    label: "Bóng Chuyền",
    icon: BadmintonIcon,
    children: [],
  },
  { key: "b8", label: "Cầu Lông", icon: VolleyballIcon, children: [] },
  { key: "b9", label: "Bóng Ném", icon: HandballIcon, children: [] },
  {
    key: "b10",
    label: "Bóng Nước",
    icon: WaterpoloIcon,
    children: [],
  },
  { key: "b11", label: "Bóng Bàn", icon: PingpongIcon, children: [] },
  { key: "b12", label: "Bida", icon: BilliardIcon, children: [] },
  { key: "b13", label: "E-Sports", icon: PCgameIcon, children: [] },
  // {
  //   key: "Fights / Muay Thai",
  //   label: "Fights / Muay Thai",
  //   icon: PCgameIcon,
  //   children: [],
  // },
  {
    key: "Olympics",
    label: "Olympics",
    icon: OlympicIcon,
    children: [
      { key: "o1", label: "Quy tắc" },
      { key: "o2", label: "Bơi Lội" },
      { key: "o3", label: "Nhảy Cầu" },
      { key: "o4", label: "Khúc Côn Cầu" },
      { key: "o5", label: "Bắn Cung / Bắn Súng" },
      { key: "o6", label: "Thể Dục Dụng Cụ" },
      { key: "o7", label: "Đua Thuyền Buồm" },
      { key: "o8", label: "Gôn" },
      { key: "o9", label: "Cưỡi Ngựa" },
      { key: "o10", label: "Đấu Kiếm" },
      { key: "o11", label: "Điền Kinh" },
      {
        key: "o12",
        label: "Judo / Đấu Vật / Taekwondo / Karate",
      },
      {
        key: "o13",
        label: "Ba Môn Phối Hợp / Năm Môn Phối Hợp Hiện Đại",
      },
      { key: "o14", label: "Cử Tạ" },
      { key: "o15", label: "Đua Xe Đạp" },
      {
        key: "o16",
        label: "Đua Thuyền Rowing / Canoeing",
      },
      { key: "o17", label: "Rugby Sevens" },
      { key: "o18", label: "Trượt Ván" },
      { key: "o19", label: "Climbing" },
      { key: "o20", label: "Softball" },
    ],
  },
  // {
  //   key: "WinterOlympics",
  //   label: "Winter Olympics",
  //   icon: OlympicIcon,
  //   children: [
  //     { key: "wo1", label: "Quy tắc" },
  //     { key: "wo2", label: "Bi Đá Trên Băng" },
  //     { key: "wo3", label: "Trượt Băng" },
  //     { key: "wo4", label: "Hai Môn Phối hợp" },
  //     { key: "wo5", label: "Trượt Tuyết" },
  //     { key: "wo6", label: "Trượt Băng Nằm Ngửa" },
  //     { key: "wo7g", label: "Xe Trượt Lòng Máng" },
  //   ],
  // },
  // {
  //   key: "Cược xiên",
  //   label: "Cược xiên",
  //   icon: "Cược xiên",
  //   children: [],
  // },
  // {
  //   key: "Hướng dẫn kèo",
  //   label: "Hướng dẫn kèo",
  //   icon: "Hướng dẫn kèo",
  //   children: [],
  // },
  // {
  //   key: "Quy tắc phòng bình luận",
  //   label: "Quy tắc phòng bình luận",
  //   icon: "Quy tắc phòng bình luận",
  //   children: [],
  // },
];
