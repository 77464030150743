import IconV1 from "src/assets/icons/icon_level1_20220930.svg";
import useAuth from "src/hooks/useAuth";
import "./index.scss";
import IC_icon_menu_MemInfor from "src/assets/icons/icon_menu_MemInfor.svg";
import IC_icon_menu_card from "src/assets/icons/icon_menu_card.svg";
import IC_icon_login from "src/assets/icons/icon_login.svg";
import IC_icon_setting from "src/assets/icons/icon_setting.svg";
import IC_icon_complian from "src/assets/icons/icon_complian.svg";
import IC_icon_about from "src/assets/icons/icon_about.svg";
import IC_icon_menu_help from "src/assets/icons/icon_menu_help.svg";
import IC_icon_footer_computer from "src/assets/icons/icon_footer_computer.svg";
import IC_icon_teach from "src/assets/icons/icon_teach.svg";
import { useState } from "react";

export const ProfileMobile = () => {
  const { auth, logout } = useAuth();
  const [ons, setOns] = useState<any>({});

  const onChangeOn = (key: string) => {
    if ((ons as any)?.[key]) {
      setOns({ ...ons, [key]: undefined });
    } else {
      setOns({ ...ons, [key]: true });
    }
  };

  const isOn = (key: string) => {
    return Boolean(ons?.[key]);
  };

  return (
    <div>
      <div ng-controller="HeaderCtrl as ctrl" className="bg_header ng-scope">
        <input type="hidden" id="hfLID" value="HUP100TYX3" />
        <input
          type="hidden"
          id="hfAID"
          value="8c2f426f601a024f3a296634d45bfef4"
        />
        <a className="btn_back"></a>
        <h1 className="bg_header_name ">TÔI</h1>
        <div className="header_R">
          <div className="btn_bell on ng-scope"></div>
        </div>
      </div>

      <div style={{ paddingTop: 40, paddingBottom: 40, minHeight: "100vh" }}>
        <div
          ng-controller="MemberCenterCtrl as ctrl"
          className="ng-scope"
          style={{ width: "100%", overflow: "scroll" }}
        >
          <ul className="member">
            <li className="memberFace">
              <img src={IconV1} className="" />
              <div
                ng-bind="ctrl.model.LoginInfo.LevelTypeName"
                className="ng-binding"
              >
                Đồng
              </div>
            </li>
            <li className="memberID">
              <div>
                <div>{auth?.user?.name} </div>
                <div className="t_blue">﻿anhla1</div>
              </div>
            </li>
            <li
              className="memberMoney t_orange ng-scope"
              ng-if="ctrl.model.LoginStatus == 2 &amp;&amp; ctrl.model.IsShowBalanceAmount"
            >
              $&nbsp;
              <span
                ng-bind="ctrl.model.BalanceAmount | number"
                className="ng-binding"
              >
                0
              </span>
            </li>
          </ul>
          <ul className="memberForm">
            <li>
              <img src={IC_icon_menu_MemInfor} />
              <div>Tư liệu hội viên</div>
            </li>
            <li>
              <img src={IC_icon_menu_card} />
              <div>Quản lí ngân hàng</div>
            </li>
            <li>
              <img src={IC_icon_login} />
              <div>Thay đổi mật khẩu</div>
            </li>
            <li>
              <img src={IC_icon_setting} />
              <div>Chuyển quỹ tự động</div>
            </li>

            <li
              className={`memberDrop marginBottom ${
                isOn("transaction") ? "on" : ""
              }`}
              onClick={() => onChangeOn("transaction")}
            >
              <div className="img_record"></div>
              <div>Giao dịch</div>
              <ul style={{ display: isOn("transaction") ? "block" : "none" }}>
                <li>Lịch sử giao dịch</li>
                <li className="ng-scope">Lịch sử chuyển điểm</li>
                <li>Khuyến mãi</li>
              </ul>
            </li>
            <li>
              <img src={IC_icon_complian} />
              <div>Khiếu nại</div>
            </li>
            <li>
              <img src={IC_icon_about} />
              <div>Giới thiệu</div>
            </li>
            <li>
              <img src={IC_icon_menu_help} />
              <div>Vấn đề thường gặp</div>
            </li>
            {/* <li className="memberDrop">
              <div className="img_download"></div>
              <div className="redC">APP</div>
              <ul>
                <li pop-open="#popShare">
                  <div id="shareAPP" className="redC">
                    Chia sẻ APP
                  </div>
                </li>
              </ul>
            </li> */}
            <li>
              <img src={IC_icon_footer_computer} />
              <div>Trở về PC</div>
            </li>
            <li className="marginBottom">
              <img src={IC_icon_teach} />
              <div>Hướng dẫn người mới</div>
            </li>
            <li className="marginBottom t_red" id="signout" onClick={logout}>
              Đăng xuất
            </li>
          </ul>
          {/* <div
            className="mask_all mask_new"
            id="excl_Popup"
            style={{ display: "none" }}
          >
            <div className="popup_container">
              <div className="popup_bg">
                <div className="popup">
                  <div className="popup_T">
                    <span>Tin nhắn</span>
                  </div>
                  <div className="popup_In">
                    <div className="ng-hide">
                      <div className="popupCenter">
                        Do tiền thưởng khuyến mãi của bạn chưa đạt lượng cược
                        cần thiết cho nên không thể sử dụng chức năng chuyển
                        điểm, nếu có thắc mắc vui lòng liên hệ hỗ trợ
                      </div>
                    </div>
                  </div>
                  <div className="popup_button">
                    <input
                      type="button"
                      className="w100"
                      value="Xác nhận"
                      id="callPopup2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mask_all" id="popShare">
            <div className="popup_container">
              <div className="popup_bg">
                <div className="popup popShare">
                  <div className="btn_close popup_close"></div>
                  <div className="popup_T">Chia sẻ APP</div>
                  <div className="popup_In">
                    <div className="popShare_btn">
                      <div className="btn_shareCopy">Sao chép</div>
                      <div className="btn_shareSafe">Lưu</div>
                    </div>
                    <div className="txt_share" id="shareCopy">
                      Sao chép thành công
                    </div>
                    <div className="txt_share" id="shareSafe">
                      Ảnh đã được lưu vào album điện thoại
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
    // </div>
  );
};
