export const parseHandicapValue = (betType: string): number => {
  const pattern = /\((-?\d+)\)/;
  const match = betType.match(pattern);

  if (match) {
    return parseInt(match[1], 10);
  }
  return 0;
};

export const parseTXValue = (betType: string): number => {
  const pattern = /\(([-+]?\d+(\.\d+)?)\)/;
  const match = betType?.match(pattern);

  if (match) {
    return parseFloat(match[1]);
  }
  return 0;
};

export const formatDateToMMDD = (inputDate: string, hasHour?: boolean) => {
  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const hour = String(date.getUTCHours()).padStart(2, "0");
  const minute = String(date.getUTCMinutes()).padStart(2, "0");

  return !hasHour ? `${day}-${month}` : `${day}-${month} ${hour}:${minute}`;
};
