import React, { createContext, useState } from "react";
import { NULL } from "sass";
import {
  AppContextType,
  Bet,
  BetFor,
  BetSelect,
  Game,
  League,
  SidebarGameTypes,
  GameTypeId,
  Snackbar,
} from "src/helper";

export const AppContext = createContext<AppContextType | null>(null);

const AppProvider = ({ children }: React.PropsWithChildren) => {
  const [snackbar, setSnackbar] = useState<Snackbar | undefined>();

  const onChangeSnackbar = (val?: Snackbar) => {
    setSnackbar(val);
  };

  const [loading, setLoading] = useState(false);
  const updateLoading = (status: boolean) => {
    setLoading(status);
  };

  const [isErrorNetwork, setErrorNetwork] = useState(false);
  const onChangeErrorNetwork = (val: boolean) => {
    setErrorNetwork(val);
  };

  const [username, setUsername] = useState("");
  const updateUsername = (username: string) => {
    setUsername(username);
  };

  const [balance, setBalance] = useState(0);
  const updateBalance = (balance: number) => {
    setBalance(balance);
  };

  const [isFastBet, setFastBet] = useState<boolean>(false);

  const onToggleFastBet = () => {
    setFastBet(!isFastBet);
  };

  const [sidebarGameType, setSidebarGameType] = useState<SidebarGameTypes>(
    SidebarGameTypes.Live
  );
  const updateSidebarGameType = (type: SidebarGameTypes) => {
    setSidebarGameType(type);
  };

  const [games, setGames] = useState<Game[]>([]);
  const updateGames = (games: Game[]) => {
    setGames(games);
  };

  const [collapseGame, setCollapseGame] = useState<boolean>(false);
  const updateCollapseGame = () => {
    setCollapseGame(!collapseGame);
  };

  const [showCart, setShowCart] = useState<boolean>(false);
  const updateShowCart = () => {
    setShowCart(!showCart);
  };
  const openCart = () => {
    setShowCart(true);
  };
  const closeCart = () => {
    setShowCart(false);
  };

  const [showRound, setShowRound] = useState<boolean>(false);
  const updateShowRound = () => {
    setShowRound(!showRound);
  };

  const [showRoundIdSelected, setShowRoundIdSelected] = useState<number | null>(
    null
  );

  const updateShowRoundIdSelected = (id: number | null) => {
    setShowRoundIdSelected(id);
  };

  const [leagues, setLeagues] = useState<League[]>([]);
  const updateLeagues = (leagues: League[]) => {
    setLeagues(leagues);
  };

  const [selectedBetList, setSelectedBetList] = useState<BetSelect[]>([]);
  const resetBetList = () => {
    setSelectedBetList([]);
  };
  const createOrDeleteSelectedBetList = (value: BetSelect) => {
    const indexFound = selectedBetList.findIndex(
      (i) => i.bet_option_id === value.bet_option_id
    );

    let newList: BetSelect[] = [];

    if (indexFound >= 0) {
      //delete
      newList = selectedBetList.filter(
        (i) => i.bet_option_id != value.bet_option_id
      );
    } else {
      // check bet_id
      // TODO sau nay bo comment ra de cuoc duoc nhieu don
      // if (value.bet_type === GameTypeId.Soccer_CorrectScore) {
      //   newList = [...selectedBetList, value];
      // } else {
      newList = [
        ...selectedBetList.filter((b) => b.bet.id != value.bet.id),
        value,
      ];
      // }
    }
    setSelectedBetList(newList);
    if (newList.length === 0) {
      setShowCart(false);
    }
  };

  const updateSelectedBetList = (value: BetSelect) => {
    const indexFound = selectedBetList.findIndex(
      (i) => i.bet_option_id === value.bet_option_id
    );
    if (indexFound < 0) return;
    setSelectedBetList([
      ...selectedBetList.slice(0, indexFound),
      value,
      ...selectedBetList.slice(indexFound + 1, selectedBetList.length),
    ]);
  };

  const [selectedBet, setSelectedBet] = useState<Bet | null>(null);
  const updateSelectedBet = (bet: Bet | null) => {
    setSelectedBet(bet);
    // if (bet !== null) {
    //   setShowCart(false);
    // }
  };

  const [betFor, setBetFor] = useState<BetFor | null>(null);
  const updateBetFor = (betFor: BetFor | null) => {
    setBetFor(betFor);
  };

  const [betOptionId, setBetOptionId] = useState<number | null>(null);
  const updateBetOptionId = (id: number | null) => {
    setBetOptionId(id);
  };

  const [gameTypeId, setGameTypeId] = useState<number>(1);
  const updateGameTypeId = (id: number) => {
    setGameTypeId(id);
  };

  return (
    <AppContext.Provider
      value={{
        isErrorNetwork,
        onChangeErrorNetwork,

        snackbar,
        onChangeSnackbar,

        loading,
        updateLoading,

        username,
        updateUsername,

        balance,
        updateBalance,

        sidebarGameType,
        updateSidebarGameType,

        games,
        updateGames,

        collapseGame,
        updateCollapseGame,

        showCart,
        updateShowCart,
        openCart,
        closeCart,

        showRound,
        updateShowRound,

        showRoundIdSelected,
        updateShowRoundIdSelected,

        leagues,
        updateLeagues,

        selectedBet,
        updateSelectedBet,

        betFor,
        updateBetFor,

        betOptionId,
        updateBetOptionId,

        gameTypeId,
        updateGameTypeId,

        isFastBet,
        onToggleFastBet,

        selectedBetList,
        createOrDeleteSelectedBetList,
        resetBetList,
        updateSelectedBetList,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
