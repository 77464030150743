import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { IPermissionsResult, IUser } from "src/interfaces";

interface IProps {
  children: any;
  permissions?: (user: IUser) => IPermissionsResult;
}

const RequireAuth = ({ children, permissions }: IProps) => {
  const { auth }: any = useAuth();
  const location = useLocation();

  if (!auth?.access_token) {
    return <Navigate to={`/`} state={{ from: location }} replace />;
  }

  if (auth?.loading) {
    return <div>Loading...</div>;
  }

  if (!auth?.user) {
    return <Navigate to={`/`} state={{ from: location }} replace />;
  }

  if (permissions) {
    const { isAllow, callback }: IPermissionsResult = permissions(auth?.user);
    if (!isAllow) {
      return callback ? (
        callback(auth?.user)
      ) : (
        <Navigate to={"/unauthorized"} state={{ from: location }} replace />
      );
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default RequireAuth;
