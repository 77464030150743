import { HistoryBet } from "src/helper";
import { httpClient } from "./instance";

export const getHistory = async (
  page = 1,
  limit = 25,
  status = 1,
  callback401?: () => void
): Promise<{ data: HistoryBet[]; count: number }> => {
  try {
    const { data } = await httpClient.get(
      `/bet-option/histories?page=${page}&limit=${limit}&status=${status}`
    );
    return data;
  } catch (error) {
    console.log(error);
    callback401?.();
    return {
      data: [],
      count: 0,
    };
  }
};
