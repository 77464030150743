import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import AppProvider from "./context/appContext";
import { AuthContextProvider } from "./context/AuthContext";
import "./index.scss";
import { LoadingPage } from "./pages/loading";
import reportWebVitals from "./reportWebVitals";
import RenderRouters from "./routers/renderRoutes";
import SnackbarComponent from "./components/snackbar";
import { NotificationContextProvider } from "./context/NotificationContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <AppProvider>
    {/* <NotificationContextProvider> */}
    <Suspense fallback={<LoadingPage />}>
      <React.StrictMode>
        <BrowserRouter>
          <AuthContextProvider>
            <RenderRouters />
            <SnackbarComponent />
          </AuthContextProvider>
        </BrowserRouter>
      </React.StrictMode>
    </Suspense>
    {/* </NotificationContextProvider> */}
  </AppProvider>
);

reportWebVitals();
