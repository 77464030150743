import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { VITE_API_URL } from "src/helper";
import { getToken } from "../utils/token";

export const httpClient = axios.create({
  baseURL: VITE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

httpClient.interceptors.request.use((request: AxiosRequestConfig) => {
  const token = getToken();

  const newHeader = {
    ...request.headers,
    Authorization: `Bearer ${token}`,
  } as AxiosRequestHeaders;

  request = {
    ...request,
    headers: newHeader,
  };

  return request;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
