import { lazy } from "react";
import { IRoute } from "src/interfaces";

// const HomePage = lazy(() => import("src/pages/home/index"));
const ProfilePage = lazy(() => import("src/pages/profile/index"));
// const SupportPage = lazy(() => import("src/pages/support/index"));
const SportPage = lazy(() => import("src/pages/sport/index"));
const OrderHistoryPage = lazy(() => import("src/pages/order-history/index"));

const listNoAuthRouters: IRoute[] = [
  // {
  //   path: "/",
  //   element: isMobile ? <MobileHomeLayout /> : <HomeLayout />,
  //   children: [
  //     { path: "/", element: <HomePage /> },
  //     {
  //       path: "/profile",
  //       element: <ProfilePage />,
  //     },
  //     {
  //       path: "/support",
  //       element: <SupportPage />,
  //     },
  //   ],
  // },
  {
    path: "/",
    element: <SportPage />,
  },
  // {
  //   path: "/order",
  //   element: <OrderHistoryPage />,
  // },
];

const listPrivateRouters: IRoute[] = [
  {
    path: "/",
    element: <SportPage />,
  },
  // {
  //   path: "/order",
  //   element: <OrderHistoryPage />,
  // },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
];

export { listNoAuthRouters, listPrivateRouters };
