import { ModalProps, Pagination, Spin, TabsProps } from "antd";
import { useCallback, useEffect, useState } from "react";
import { getHistory } from "src/apis/history";
import { HistoryBet, SCREEN_HEIGHT } from "src/helper";
import { ButtonComponent, ModalComponent, TabsComponent } from "../share";
import Adjusted from "./Adjusted";
import ExchangeCash from "./ExchangeCash";
import GiftGivingHistory from "./GiftGivingHistory";
import Unprocessed from "./Unprocessed";
import "./index.scss";
import "./min.scss";

const BetHistoryModal = ({ onClose, ...rest }: ModalProps) => {
  const [activeTabKey, setActiveTabKey] = useState("2");
  const [historyList, setHistorybet] = useState<HistoryBet[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  // const { isErrorNetwork } = useApp() as AppContextType;

  const handleGetHistory = useCallback(
    async (page?: number) => {
      setLoading(true);
      const res = await getHistory(page ?? 1, 20, Number(activeTabKey === "3"));
      if (res != null) {
        setHistorybet(res.data);
        setCount(res.count);
      }
      setLoading(false);
    },
    [activeTabKey]
  );

  const handleTabChange = useCallback((key: string) => {
    setActiveTabKey(key);
  }, []);

  const handleRefetch = () => {
    if (activeTabKey === "2" || activeTabKey === "3") {
      setCount(0);
      handleGetHistory();
    }
  };

  useEffect(() => {
    handleGetHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const operations = (
    <ButtonComponent
      buttonLabel={"Làm mới"}
      style={{ marginRight: 24 }}
      onClick={handleRefetch}
    />
  );

  const onChangeLoading = (val: boolean) => {
    setLoading(val);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Đổi tiền mặt",
      children: <ExchangeCash onChangeLoading={onChangeLoading} />,
    },
    {
      key: "2",
      label: "Chưa kết toán",
      children: <Unprocessed historyList={historyList} />,
    },
    {
      key: "3",
      label: "Đã kết toán",
      children: <Adjusted />,
    },
    {
      key: "4",
      label: "Lịch sử tặng quà",
      children: <GiftGivingHistory />,
    },
  ];

  const onChangePage = (page: number, _: number) => {
    handleGetHistory(page);
  };

  return (
    <ModalComponent
      footer={[]}
      width={"90%"}
      height={SCREEN_HEIGHT - 100}
      title=" "
      styles={{ header: { height: 30 } }}
      closable={true}
      onCancel={onClose}
      maskClosable={false}
      {...rest}
    >
      <Spin spinning={loading}>
        <div className="BetHistoryModalStyle OrderStyle">
          <div className="header">Lịch sử đặt cược</div>
          <TabsComponent
            items={items}
            activeKey={activeTabKey}
            onChange={handleTabChange}
            tabBarExtraContent={operations}
            // style={{ height: SCREEN_HEIGHT - 270 }}
          />
        </div>
        <div
          style={{ paddingTop: 8, display: "flex", justifyContent: "center" }}
        >
          <Pagination
            onChange={onChangePage}
            defaultCurrent={1}
            // total={100}
            total={count}
            pageSize={20}
            showSizeChanger={false}
          />
        </div>
      </Spin>
    </ModalComponent>
  );
};

export default BetHistoryModal;
