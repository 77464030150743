import { NoBetIcon } from "src/assets/icons";

export const Adjusted = ({ content }: { content?: any }) => {
  return (
    <div className="AdjustedStyle">
      <div className="th">
        <div>Thông tin đơn cược</div>
        <div>Nội dung đặt cược</div>
        <div>Tiền cược</div>
        <div>Số dư sau cược</div>
        <div>Hoàn trả tối đa</div>
      </div>
      {content ? (
        <div className="content"></div>
      ) : (
        <div className="no-content">
          <NoBetIcon />
          <div>Tạm thời không có đợn cược</div>
        </div>
      )}
    </div>
  );
};

export default Adjusted;
