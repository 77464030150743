import { useEffect, useState } from "react";

export interface WatchComponentProps {
  isHomeMode?: boolean;
}

export const WatchComponent = ({ isHomeMode }: WatchComponentProps) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalID = setInterval(() => {
      setTime(new Date());
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalID);
  }, []); // empty dependency array to run only once on component mount

  const formattedTime = () => {
    const dd = String(time.getDate()).padStart(2, "0");
    const MM = String(time.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0
    const hh = String(time.getHours()).padStart(2, "0");
    const mm = String(time.getMinutes()).padStart(2, "0");
    const ss = String(time.getSeconds()).padStart(2, "0");
    return isHomeMode
      ? `${dd}-${MM} ${hh}:${mm}`
      : `${dd}-${MM} ${hh}:${mm}:${ss}`;
  };

  return <div>{formattedTime()}</div>;
};
