import { NoBetIcon } from "src/assets/icons";
import { HistoryBet, numberWithCommas } from "src/helper";
import {
  formatDateToMMDD,
  parseHandicapValue,
  parseTXValue,
} from "src/utils/stringHelper";

interface UnprocessedProps {
  historyList: HistoryBet[];
}

export const Unprocessed = ({ historyList }: UnprocessedProps) => {
  return (
    <div className="UnprocessedStyle">
      <div className="th">
        <div>Thông tin đơn cược</div>
        <div>Nội dung đặt cược</div>
        <div>Tiền cược</div>
        <div>Số dư sau cược</div>
        <div>Hoàn trả tối đa</div>
      </div>
      {historyList.length ? (
        <div className="content">
          {historyList?.map((h) => {
            const isTX = h.bet_option.bet?.bet_type.includes("-TX");
            return (
              <div className="tr" key={h.id}>
                <div className="info">
                  <div className="RT_row">
                    Cược：
                    <span className="t_size12">
                      {formatDateToMMDD(h.created_at, true)}
                    </span>
                  </div>{" "}
                  <div className="RT_row">
                    Mã đơn：<span className="t_size12">{h.id}</span>
                  </div>{" "}
                  <div className="RT_row">
                    Tỉ lệ：<span className="t_size12">Không gồm tiền vốn</span>
                  </div>{" "}
                  <div className="RT_row">
                    Loại：
                    <span className="t_size12"> {isTX ? "Tài xỉu" : "FT"}</span>
                  </div>{" "}
                </div>

                <div style={{ textAlign: "left" }}>
                  <div className="RT_row">Bóng Đá Trực tiếp - [FT]</div>{" "}
                  <div className="RT_row">
                    {h.bet_option.bet?.round?.home}
                    <span className="t_orange"> (H)</span>
                    <span className="t_gray"> VS </span>
                    {h.bet_option.bet?.round?.guest}
                  </div>{" "}
                  <div className="RT_row">
                    <span className="t_gray">Đặt cược：</span>
                    <div className="RT_rowIn">
                      <span className="t_red">
                        {`(${h?.bet_option.bet?.round?.score?.home ?? "0"} : ${
                          h.bet_option.bet?.round?.score?.home ?? "0"
                        })`}{" "}
                      </span>
                      <span className="t_blue">{h.bet_option.name}</span>
                      <div className="t_inlineBox">
                        <span className="t_red">
                          {isTX
                            ? Math.abs(
                                parseTXValue(h.bet_option.bet?.bet_type ?? "")
                              )
                            : parseHandicapValue(
                                h.bet_option.bet?.bet_type ?? ""
                              )}
                        </span>{" "}
                        @<span className="t_red">{h.bet_option.odds}</span>
                      </div>
                    </div>
                  </div>{" "}
                  <div className="RT_row t_gray">
                    Thời gian thi đấu：
                    <span className="t_black">{`${h?.bet_option?.bet?.round?.round.step} ${h?.bet_option?.bet?.round?.round.time}`}</span>
                  </div>{" "}
                  <span className="icon_RT_ok">Đã xác nhận</span>
                </div>
                <div>
                  <b className="t_blue">{h.amount / 1000}</b>
                </div>
                <div>
                  <b>
                    {numberWithCommas(Number(h?.balance_after ?? 0) / 1000)}
                  </b>
                </div>
                <div>
                  <b>
                    { ((h.amount * (h?.bet_option?.odds + 1)) / 1000).toFixed(2)
                    }
                  </b>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="no-content">
          <NoBetIcon />
          <div>Tạm thời không có đợn cược</div>
        </div>
      )}
    </div>
  );
};

export default Unprocessed;
